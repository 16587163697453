import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import MainGrid from '../styling/MainGrid'
import theme from '../styling/theme'
import VerticalPadding from '../styling/VerticalPadding'

interface TextColsProps {
  leftCol: any
  rightCol: any
  heading: string
}
const TextCols: FunctionComponent<TextColsProps> = ({
  heading,
  leftCol,
  rightCol,
}) => {
  return (
    <Container>
      <VerticalPadding>
        <MainGrid>
          <h2>{heading}</h2>
          <Inner>
            <Col>{leftCol}</Col>
            <Col>{rightCol}</Col>
          </Inner>
        </MainGrid>
      </VerticalPadding>
    </Container>
  )
}

export default TextCols

const Container = styled.section`
  & h2 {
    margin-bottom: 15px;
    @media (min-width: 768px) {
      max-width: 48%;
    }
  }
`

const Inner = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`
const Col = styled.div`
  & p {
    margin-bottom: 8px;
  }

  & li {
    font-size: 13px;
    line-height: 27px;
    font-weight: 300;
    color: ${theme.text.greyLight};
    margin-bottom: 2px;
    margin-top: 0px;
    @media (min-width: 768px) {
      font-size: 1.6rem;
      line-height: 2.7rem;
      margin-top: 2px;
    }
  }

  @media (min-width: 768px) {
    width: 48%;
  }
`
