import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import theme from '../styling/theme'
import SmallGrid from '../styling/SmallGrid'
import VerticalPadding from '../styling/VerticalPadding'

interface TextProps {
  tag?: string
  heading: string
}
const TextCentered: FunctionComponent<TextProps> = ({ tag, heading }) => {
  return (
    <Container>
      <VerticalPadding>
        <SmallGrid>
          {tag && <p className="section-tag">{tag}</p>}
          {heading && <h4>{heading}</h4>}
        </SmallGrid>
      </VerticalPadding>
    </Container>
  )
}

export default TextCentered

const Container = styled.section`
  text-align: center;
  width: 100%;

  & .section-tag {
    margin-bottom: 10px;
  }

  & h4 {
    font-family: Michroma;
  }
`
