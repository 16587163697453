import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import SectionHeading from '../shared/SectionHeading'
import theme from '../styling/theme'
import linkedin from '../../images/social-media/linkedin.svg'
import MainGrid from '../styling/MainGrid'
import VerticalPadding from '../styling/VerticalPadding'
import InlineLink from '../shared/InlineLink'

interface Member {
  img: string
  name: string
  role: string
  body: Array<string>
  social?: string
}
interface MeetProps {
  heading: string
  team: Array<Member>
}
const MeetOurTeamCards: FunctionComponent<MeetProps> = ({ heading, team }) => {
  return (
    <Container>
      <MainGrid>
        <VerticalPadding>
          {heading && <SectionHeading center>{heading}</SectionHeading>}
          <Grid>
            {team &&
              team.map((item, key) => {
                return (
                  <Card key={key} hideMobile={key === 2}>
                    {item.img && <Img src={item.img} alt={item.name} />}
                    {item.name && <Name>{item.name}</Name>}
                    {item.role && (
                      <Role className="section-tag">{item.role}</Role>
                    )}
                    {item.body &&
                      item.body.map((item, key) => {
                        return <P key={key}>{item}</P>
                      })}
                    {item.social && (
                      <Linkedin href={item.social} target="_blank">
                        <img src={linkedin} alt="Linkedin logo" />
                      </Linkedin>
                    )}
                  </Card>
                )
              })}
          </Grid>
          <InlineLink
            center
            text="Show all the team"
            icon
            to={
              (process.env.BASE === '/' ? '' : process.env.BASE) +
              '/meet-the-team'
            }
          />
        </VerticalPadding>
      </MainGrid>
    </Container>
  )
}

export default MeetOurTeamCards

const Container = styled.section`
  @media (min-width: 768px) {
    padding-bottom: 5rem;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 20px;
  margin: 10px 0 36px;
  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 3rem;
    margin: 30px 0 63px;
  }
`
interface CardProps {
  hideMobile?: boolean
}

const Card = styled.div<CardProps>`
  border: 1px solid #eeeeee;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  padding: 27px 33px 66px;
  display: ${props => (props.hideMobile ? 'none' : 'block')};
  @media (min-width: 768px) {
    display: block;
  }
  @media (min-width: 1024px) {
    padding: 47px 40px 54px;
  }
`

const Img = styled.img`
  width: 113px;
  height: 113px;
  margin-bottom: 26px;
`

const Name = styled.p`
  font-weight: bold;
  color: ${theme.text.main};
  margin-bottom: 6px;
`

const Role = styled.p`
  margin-bottom: 20px;
`

const P = styled.p`
  margin-bottom: 6px;
  @media (min-width: 768px) {
    margin-bottom: 9px;
  }
`

const Linkedin = styled.a`
  margin-top: 4px;
  background: ${theme.brand.blue};
  height: 34px;
  width: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  & img {
    width: 15px;
    height: 15px;
  }

  &:not(:last-of-type) {
    margin-right: 6px;
  }

  &:hover {
    background: ${theme.text.main};
  }
  @media (min-width: 768px) {
    margin-top: 21px;
  }
`
