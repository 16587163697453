import React from 'react'
import Layout from '../components/Layout'
import CallBackBanner from '../components/CallBackBanner'
import AboutUsHeader from '../components/Header/AboutUsHeader'
import GeneralEnquiryForm from '../components/shared/Form/GeneralEnquiryForm'
import ImageAndTextColumns from '../components/ImageAndTextColumns/ImageAndTextColumns'
import ImageAndCard from '../components/ImageAndCard/ImageAndCard'
import ImageAndCardTaxCredit from '../components/ImageAndCard/ImageAndCardTaxCredit'
import img from '../images/about-us/about_us.svg'
import imgTable from '../images/about-us/our_approach.svg'
import imageandcard from '../images/tax-credit/team_meeting_graph.svg'
import TextCentered from '../components/TextCentered/TextCentered'
import SmallBanner from '../components/SmallBanner/SmallBanner'
import rda from '../images/about-us/the_rda_way.svg'
import mission from '../images/about-us/mission.svg'
import MeetOurTeam from '../components/MeetOurTeam/MeetOurTeamCards'
import PromotionBanner from '../components/PromotionBanner/PromotionBanner'
import TextCols from '../components/TextCols/TextCols'
import { GetSortedMeetTheTeam } from '../components/MeetOurTeam/MeetOutTeamData'
// import { MeetTheTeamData } from '../components/MeetOurTeam/MeetOutTeamData'

const breadcrumbData = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'About us',
    url: '/about-us',
  },
]

const AboutUs = () => {
  return (
    <Layout
      activeLink="/about-us"
      title="R&D About us"
      description="R&D About us"
    >
      <AboutUsHeader
        breadcrumbData={breadcrumbData}
        heading="About us"
        img={img}
        alt="Team socialiazing"
      />
      <TextCentered
        tag="HOW WE DESCRIBE OURSELVES"
        heading="RDA is an expert in achieving Research and Development tax credits
        for businesses across all industry sectors through a unique, specialist
        consultancy-and-advocacy approach.
        "
      />
      <ImageAndTextColumns
        tag="OUR APPROACH"
        heading="How we are different"
        body={[
          'Our unique, one-to-one consultancy approach to client claims delivers the best possible outcome for enterprises of any size, from SMEs to large corporates.',
          'We know that no one knows your R&D like you do, but sometimes it is tough to explain. That is why we set out to truly understand how you have conducted your R&D: what motivated it, what you went through and what results you achieved.',
          'This forensic examination of each project’s history is then coupled with careful analysis of the financial aspects so we can properly reflect all the direct and indirect costs you have incurred. Because only by truly understanding everything that has gone into a project can an effective claim be made under the R&D Tax Credits Scheme.',
        ]}
        img={imgTable}
        imgAlt="People in a meeting"
      />
      <TextCols
        heading="We tell your story"
        leftCol={
          <div>
            <p>
              We pride ourselves that we are not merely expert tax agents alone,
              but expert communicators too.
            </p>
            <p>
              That is vital to ensuring that your R&D work is conveyed in a
              language HMRC understands. With us as your RDA, you can
              be confident that HMRC will truly know the lengths you have gone
              to for your new products and processes.
            </p>
            <p>
              A successful claim will generate vital supplementary cash flows
              for your firm, genuinely reflecting and rewarding the effort and
              ingenuity you have put into your projects.
            </p>
            <p>
              We also offer the specialist support your accountancy firm or tax
              advisor often needs when preparing and submitting complicated R&D
              claims. That’s because our claims experience and our trusted
              relationships within the relevant department at HMRC will ensure
              your R&D is conveyed in the best possible light.{' '}
            </p>
          </div>
        }
        rightCol={
          <div>
            <p>
              All this is why RDA are trusted by firms ranging in
              turnover from £1million to £2 billion to support their innovation
              and handle their specialist R&D claims submissions, year after
              year.
            </p>
            <p>
              In addition to the preparation and submission of your R&D Tax
              Credits Scheme claim, with RDA, you will have access to:
            </p>
            <ul>
              <li>a dedicated Client Manager on call</li>
              <li>
                plain-English bulletins on all government R&D regulations and
                directives
              </li>
              <li>a confidential R&D record-keeping service</li>
              <li>R&D best-practice coaching and workshops</li>
              <li>Intellectual-property assessment and advocacy</li>
            </ul>
          </div>
        }
      />

      <ImageAndCardTaxCredit
        flip
        image={mission}
        altImg="People discussing about graph"
        heading="We are always on hand"
        content={[
          'The purpose of HMRC’s R&D Tax Credits Scheme is to stimulate investment in research and development in the UK economy. We know your R&D is continuously evolving, so that is why we maintain an ongoing, year-round relationship with our partners to work not only on their tax-relief claim but also to help facilitate their R&D itself.',
          'With RDA on hand, you will maximise the returns you get both from your tax-relief application and from your finished R&D.',
          <p className="p-large">
            Develop <i>together</i>. It’s the RDA Way
          </p>,
        ]}
      />
      <SmallBanner
        heading="RDA are trusted by firms ranging in turnover from…"
        bold="£1million to £2 billion"
        light="to handle their specialist R&D claims submissions to HMRC, year after year."
      />
      <ImageAndTextColumns
        flip
        tag="The RDA way"
        heading="Mission Statement"
        body={[
          'At RDA we know how privileged we are to be facilitators of the brilliant best of British business, fuelling the imagination, innovation and iteration that make the UK one of the world’s leading-edge economies. By extending our deep well of knowledge of Research and Development across all industry sectors, we can successfully recognise, reflect and reward our clients’ own unique endeavours in R&D.',
          'With a firm commitment to excellent communication, we can deliver the highest-quality, most professional experience possible for our clients, through every step of their R&D-claim journey. We will always act with integrity, taking great care in our research of their affairs and activity while developing a thorough understanding of their people and processes.',
          'And for the team at RDA, we pledge to be held accountable for providing a flexible working environment where they can flourish around a breadth and variety of work that makes every day interesting.',
        ]}
        img={rda}
        imgAlt="People in a meeting"
      />
      <ImageAndCard
        flip
        image={imageandcard}
        altImg="People discussing about a graph"
        tag="OUR MISSION"
        heading="Understanding, satisfaction and success"
        content="It is our mission to ensure you receive the best possible standards of service and delivery. We are so confident in our methods and outcomes that we will not charge you a penny unless your claim is successful."
        bold={
          <b>
            Develop <i>together</i>. It’s the RDA Way
          </b>
        }
      />
      <MeetOurTeam
        heading="Meet our team"
        team={GetSortedMeetTheTeam().slice(0,6)}
      />
     
      <PromotionBanner />
      <GeneralEnquiryForm />
      <CallBackBanner />
    </Layout>
  )
}

export default AboutUs
