import React from 'react'
import styled from 'styled-components'
import MainGrid from '../styling/MainGrid'
import Breadcrumb from '../shared/Breadcrumb/Breadcrumb'

interface IBreadcrumb {
  title: string
  url: string
}

interface IAboutUsHeader {
  breadcrumbData?: Array<IBreadcrumb>
  heading: string
  img: string
  alt: string
}

const AboutUsHeader = ({
  breadcrumbData,
  heading,
  img,
  alt,
}: IAboutUsHeader) => {
  return (
    <Container>
      <MainGrid>
        <Breadcrumb data={breadcrumbData} />
        <TextWrapper>
          {heading && <MainHeading>{heading}</MainHeading>}
        </TextWrapper>
        <Img src={img} alt={alt} />
      </MainGrid>
    </Container>
  )
}

export default AboutUsHeader

const Container = styled.header`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const TextWrapper = styled.div`
  width: 100%;
  text-align: center;
  padding: 27px 0;
  @media (min-width: 1024px) {
    padding: 45px 0 0;
  }
`

const MainHeading = styled.h1``

const Img = styled.img``
