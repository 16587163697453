import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import theme from '../styling/theme'
import VerticalPadding from '../styling/VerticalPadding'
import SmallGrid from '../styling/SmallGrid'

interface SmallProps {
  heading: string
  bold: string
  light: string
}
const SmallBanner: FunctionComponent<SmallProps> = ({
  heading,
  bold,
  light,
}) => {
  return (
    <SmallGrid>
      <VerticalPadding>
        <Container>
          <Col>{heading && <h4>{heading}</h4>}</Col>
          <RightCol>
            {bold && <Bold>{bold}</Bold>}
            {light && <Light className="p-large">{light}</Light>}
          </RightCol>
        </Container>
      </VerticalPadding>
    </SmallGrid>
  )
}

export default SmallBanner

const Container = styled.section`
  background: ${theme.brand.blue};
  border-top: 10px solid ${theme.brand.hover};
  padding: 27px 35px 46px;
  display: flex;
  flex-direction: column;

  & h4 {
    color: white;
    font-weight: 300;
  }
  @media (min-width: 768px) {
    padding: 50px 70px 60px;
    flex-direction: row;
    justify-content: space-between;
  }
`
const Col = styled.div`
  margin-bottom: 12px;
  @media (min-width: 768px) {
    width: 33%;
    margin-bottom: 0px;
  }
  @media (min-width: 1024px) {
    width: 42%;
  }
`

const RightCol = styled(Col)`
  margin-bottom: 0px;
  @media (min-width: 768px) {
    width: 52%;
  }
`
const Bold = styled.h3`
  font-family: Roboto;
  font-weight: bold;
  color: white;

  @media (min-width: 768px) {
    line-height: 32px;
  }
  @media (min-width: 1024px) {
    line-height: 45px;
  }
`
const Light = styled.p`
  color: white;
`
