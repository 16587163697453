import React, { useState } from 'react'
import styled from 'styled-components'
import MainGrid from '../styling/MainGrid'
import theme from '../styling/theme'
import Button from '../shared/Button/Button'
import RequestCallBackModal from '../shared/Modal/RequestCallBackModal'
import MakeClaimModal from '../shared/Modal/MakeClaimModal'

interface IImageAndCard {
  image: string
  altImg: string
  tag?: string
  heading?: string
  content?: string
  list?: Array<string>
  ctaUrl1?: string
  ctaText1?: string
  ctaUrl2?: string
  ctaText2?: string
  bold?: string
  flip?: boolean
}

const ImageAndCard = ({
  image,
  altImg,
  tag,
  heading,
  content,
  list,
  ctaText1,
  ctaText2,
  bold,
  flip,
}: IImageAndCard) => {
  const [showCallBackModal, toggleCallBackModal] = useState(false)
  const [showClaimModal, toggleClaimModal] = useState(false)
  return (
    <Container>
      <MakeClaimModal
        showModal={showClaimModal}
        toggleModal={() => toggleClaimModal(false)}
        type={'Get in touch'}
        body={'Inactive account'}
      />
      <RequestCallBackModal
        showModal={showCallBackModal}
        toggleModal={() => toggleCallBackModal(false)}
        type={'Request a call back'}
        body={'Inactive account'}
      />
      <MainGrid>
        <Inner flip={flip}>
          <ImgMask>
            <Img src={image} alt={altImg} />
          </ImgMask>
          <Card>
            {tag && <p className="section-tag">{tag}</p>}
            {heading && <h3>{heading}</h3>}
            {content && <p className="p-large">{content}</p>}
            {list && (
              <ul>
                {list.map((item, key) => {
                  return <li key={key}>{item}</li>
                })}
              </ul>
            )}
            {ctaText1 && (
              <ButtonContainer>
                <Button onClick={() => toggleClaimModal(true)}>
                  {ctaText1}
                </Button>

                <Button onClick={() => toggleCallBackModal(true)} secondary>
                  {ctaText2}
                </Button>
              </ButtonContainer>
            )}

            {bold && <Bold className="p-large">{bold}</Bold>}
          </Card>
        </Inner>
      </MainGrid>
    </Container>
  )
}

export default ImageAndCard

const Container = styled.section`
  margin-bottom: 15px;
`

const Inner = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: ${props => (props.flip ? 'row-reverse' : 'row')};
    justify-content: space-between;
    align-items: center;
  }
`

const ImgMask = styled.div`
  width: 100%;

  @media (min-width: 768px) {
    width: 48%;
  }
`

const Img = styled.img`
  width: 100%;
`

const Card = styled.div`
  box-shadow: 0 -29px 54px 1px rgba(100, 100, 100, 0.1);
  background: white;
  width: 100%;
  padding: 52px 30px 43px;
  margin-top: 25px;
  border-radius: 4px;
  @media (min-width: 768px) {
    width: 48%;
    margin-top: 0;
  }

  @media (min-width: 1024px) {
    padding: 80px 55px 75px;
  }

  & h3 {
    margin-bottom: 17px;

    @media (min-width: 768px) {
      margin-bottom: 24px;
    }
  }

  & .p-large {
    margin-bottom: 10px;
    color: ${theme.text.greyLight};
  }

  & li::before {
    margin-right: 6px;
    height: 20px !important;
    width: 100% !important;
    max-width: 20px;
  }
  & li {
    color: ${theme.text.greyLight};
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 7px;

    @media (min-width: 1024px) {
      font-size: 18px;
      line-height: 28px;
    }
  }

  & ul {
    margin-bottom: 30px;

    @media (min-width: 1024px) {
      margin-bottom: 25px;
    }
  }
`
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    flex-direction: row;
  }

  & a:first-of-type {
    margin-bottom: 10px;

    @media (min-width: 1024px) {
      margin-bottom: 0;
      margin-right: 10px;
    }
  }
`
const Bold = styled.p`
  font-weight: bold;
  color: ${theme.text.main} !important;
`
